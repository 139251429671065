import { useEffect, useState } from "react";

const LayoutPaintings = ({ paintings, artist }) => {

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    const [filteredPaintings, setFilteredPaintings] = useState(null);


    useEffect(() => {
        console.log(artist);
        const filtered = paintings.filter(painting => painting.artist == artist);
        setFilteredPaintings(filtered);
    }, [artist]);

    return (
        <div>
            <div className={isMobile ? "layoutPaintings2" : "layoutPaintings"}>
                {filteredPaintings && filteredPaintings.map((painting) => (
                    <div className='blog-preview2 spaceBetween column' key={painting.id}>
                        <div>
                            <div className='textClient'> "{painting.name}"</div>
                            <p>{painting.paint}</p>
                            <img className='width100' src={painting.url} alt="stars" />
                        </div>
                        <div>
                            <hr className='hrline' />
                            <p className='namesVoice'>{painting.size} | {painting.price}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default LayoutPaintings;