const Footer = () => {

    const handleClick = (e, num) => {
        e.preventDefault();
        if (num == 1) {
            window.location.href = "/Profile";
        }
        else {
            window.location.href = "/home#firstSection";
        }
    }

    return (
        <div className="footer">
            <div className="section1Footer">
                <div className="footer1row">
                    <div className="linesInFooter1">
                        Contact Info
                    </div>
                    <br />
                    <div className="linesInFooter2">
                        +359 89 3350100
                    </div>
                    <div className="linesInFooter2">
                        +359 88 7275791
                    </div>
                    <div className="linesInFooter2">
                        filevisions@gmail.com
                    </div>
                    <br />
                    <div className="linesInFooter3">
                        Sofia
                    </div>
                    <div className="linesInFooter3">
                        Bulgaria
                    </div>
                </div>
                <div className="footer1row">
                    <div className="linesInFooter1">
                        Quick Links
                    </div>
                    <br />
                    <div className="linesInFooter3" onClick={(e) => handleClick(e, 1)}>
                        Artists
                    </div>
                    <br />
                    <div className="linesInFooter3" onClick={(e) => handleClick(e, 2)}>
                        Art Styles
                    </div>
                    <br />
                </div>
                <div className="footer1row">
                    <br />

                    <br />
                    <div className="linesInFooter3">
                        Contact us
                    </div>
                    <br />
                    <div className="linesInFooter3">
                        Terms of Use
                    </div>
                    <br />
                    <div className="linesInFooter3">
                        Privacy Policy
                    </div>
                </div>
                <div className="footer4row">
                    <div className="linesInFooter1">
                        Business Hours
                    </div>
                    <br />
                    <div className="linesInFooter3">
                        <div>Mon:</div>
                        <div>
                            10:00 AM – 4:00 PM
                        </div>
                    </div>
                    <div className="linesInFooter3">
                        <div>  Tue: </div>
                        <div> 10:00 AM – 4:00 PM</div>
                    </div>
                    <div className="linesInFooter3">
                        <div>Wed: </div> <div>10:00 AM – 4:00 PM</div>
                    </div>
                    <div className="linesInFooter3">
                        <div> Thu:</div> <div>10:00 AM – 4:00 PM</div>
                    </div>
                    <div className="linesInFooter3">
                        <div>Fri:</div> <div>10:00 AM – 4:00 PM</div>
                    </div>
                    <div className="linesInFooter3">
                        <div>Sat:</div> <div>10:00 AM – 4:00 PM</div>
                    </div>
                    <div className="linesInFooter3">
                        <div>Sun:</div> <div>10:00 AM – 4:00 PM</div>
                    </div>
                </div>
            </div>
            <div className="section2Footer">

            </div>
            <div className="section3Footer">
                © 2024 E&M Art Gallery. All rights reserved
            </div>
        </div>
    );
}

export default Footer;