import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import leftArr from './assets/CaretLeft.svg';
import rightArr from './assets/CaretRight.svg';
import leftArrBlack from './assets/CaretLeftBlack.svg';
import rightArrGrey from './assets/CaretRightGrey.svg';
import quotes from './assets/quotes.svg';
import star from './assets/Star.svg';
import { useEffect, useState } from 'react';


const ArtworkShowcase = ({ clientVoices }) => {

    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    const [activePage, setActivePage] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(clientVoices.length / 3);
    const [filteredVoices, setFilteredVoices] = useState(clientVoices.slice(0, 3));


    const handleCLick = (which) => {
        if (which === +1) {
            setActivePage(activePage + 1);
        }
        if (which === -1) {
            setActivePage(activePage - 1);
        }

        // setFilteredVoices(clientVoices.slice(activePage * 3, 3 * activePage + 3));

    }


    useEffect(() => {
        console.log(activePage);
        console.log(numberOfPages);
        setFilteredVoices(clientVoices.slice(activePage * 3, 3 * activePage + 3));
        if (isMobile) {
            setNumberOfPages(clientVoices.length);
            setFilteredVoices(clientVoices.slice(activePage, activePage + 1));
        }
    }, [activePage]);

    // const filteredVoices = activePage == 0 ? clientVoices.slice(0, 3) : clientVoices.slice(3, 6);
    // const filterVoices = 0;
    // for (let i = 0; i < numberOfPages; i++) {
    //     if(activePage == numberOfPages){
    //         filteredVoices = clientVoices.slice(activePage*3, 3*activePage);
    //     }
    // }

    // console.log(clientVoices);
    // console.log(activePage);
    return (
        <div id="paintings" className='clientVoicesSection'>
            <div className="clientVoicesHeader">
                <div className="headerClientVoices">
                    Artwork Showcase
                </div>
                {
                    activePage == 0 ?
                        <div className="arrowsNextPrev">
                            <img src={leftArr} alt="arrLeft" className='activeArrow' onClick={() => handleCLick(0)} />
                            <img src={rightArr} alt="arrRight" className='activeArrow' onClick={() => handleCLick(+1)} />
                        </div>
                        :
                        activePage < (numberOfPages - 1) ?
                            <div className="arrowsNextPrev">
                                <img src={leftArrBlack} alt="arrLeft" className='activeArrow' onClick={() => handleCLick(-1)} />
                                <img src={rightArr} alt="arrRight" className='activeArrow' onClick={() => handleCLick(+1)} />
                            </div> :
                            <div className="arrowsNextPrev">
                                <img src={leftArrBlack} alt="arrLeft" className='activeArrow' onClick={() => handleCLick(-1)} />
                                <img src={rightArrGrey} alt="arrRight" className='activeArrow' onClick={() => handleCLick(0)} />
                            </div>

                }
            </div>

            <div className="blog-list">
                {filteredVoices.map((voice) => (
                    <div className='blog-preview spaceBetween column' key={voice.id}>
                        <div>
                            <div className='spaceBetween'>
                                <div className='starsSatisfaction'>
                                    {
                                        Array.from({ length: voice.stars }, (_, index) => (
                                            <img key={index} src={star} alt="stars" />
                                        ))
                                    }
                                </div>
                                <div className='quotes'>
                                    <img src={quotes} alt="quotes" />
                                </div>
                            </div>
                            <p className='textClient'> "{voice.name}"</p>
                            <img className='width100' src={voice.url} alt="stars" />
                        </div>
                        <div>
                            <hr className='hrline' />
                            <p className='namesVoice'>{voice.size} | {voice.price}</p>
                        </div>
                    </div>
                ))}
            </div>


            <div className='twoButtonsClientSatisfaction'>

                {/* <Link to='/Profile#Artists' className="twoButtns googleReviews text-decNone">
                    See Artists
                </Link> */}


                <a href="/Profile#Artists" className="twoButtns scheduleConsultation text-decNone">
                    See Artists
                </a>

            </div>
        </div>
    );
}

export default ArtworkShowcase;