import Navbar from './Navbar';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Create from './Create';
import BlogDetails from './BlogDetails';
import { useState } from 'react';
import Footer from './Footer';
import Profile from './Profile';

function App() {
  // const title = 'Welcome to the new blog';
  // const likes = 50;
  // const person = { name: "yosi", age: 30 };
  // const link = "http:///www.google.com";

  return (
    <Router>
      <div className="App">
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <Navbar />
        <div className="content">
          <Switch>
            <Route exact path="/home">
              <Home  ></Home>
            </Route>
            <Route exact path="/Profile">
              <Profile></Profile>
            </Route>
            <Route path="/">
              <Home ></Home>
            </Route>
          </Switch>
        </div>

        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
