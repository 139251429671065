import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import atelier from './assets/atelier.png';

const AboutUsSection = ({ loggedIn, blogs }) => {
    return (
        <div className="aboutUsSection">
            <div className="aboutUsText">
                <div>
                    <div className='aboutUsTitle'>
                        {blogs[5].title}
                    </div>
                    <br />
                    <div className='textAbout'>
                        {blogs[5].body}
                    </div>
                    {
                        loggedIn === true ?

                            <Link className="adminEditLink" to={`/blogss/${5}`}>
                                Edit
                            </Link>
                            : null
                    }
                </div>

                <div className='width100'>
                    <div className='lineAbout'>

                    </div>
                    <div className='plus465Clients'>
                        <div className="bolded465">
                            10
                        </div>
                        satisfied clients
                        <span style={{ width: '8%' }}></span>
                        <div className="bolded465">
                            30+
                        </div>
                        available artwork
                    </div>
                </div>

            </div>
            <div className="aboutUsPhoto">
                <img className='atelierBorderRadius' src={atelier} alt="art-atelier" />
                <div className="textChicagoOffice"> Art Atelier</div>
            </div>
        </div>
    );
}

export default AboutUsSection;