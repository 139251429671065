import { useEffect, useState } from "react";


import arrow from './assets/right-arrow.svg';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Artists = ({ loggedIn, blogs, onArtistChange }) => {
    const [highlited, setHighlited] = useState(1);

    useEffect(() => {
        const textElement = document.querySelector('.textImageCommitment');
        const lineHeight = window.getComputedStyle(textElement).getPropertyValue('line-height');
        textElement.style.setProperty('--lineHeight', lineHeight);

        // Calculate the number of lines and adjust margin-top
        const numberOfLines = textElement.clientHeight / parseFloat(lineHeight);
        const adjustedMarginTop = -1 * numberOfLines * parseFloat(lineHeight);

        textElement.style.marginTop = `${adjustedMarginTop}px`;
    });

    const handleCick = (number) => {
        switch (number) {
            case 1:
                setHighlited(1);
                onArtistChange('Milena Fileva');
                break;
            case 2:
                setHighlited(2);
                onArtistChange('Emilia Vasileva');
                break;
            default:
                setHighlited(1);
                break;
        }

    }

    return (
        <div className="commitmentSection">
            <hr />
            <h1 className="commitmentTitle">
                Artists
            </h1>
            <h3 className="commitmentSubheader">
                Despite the diverse styles and themes of our paintings, they all share a common creation process
                that transcends time. Standing before the canvas, minutes melt away as we become lost in the act of painting.
                What may begin as a simple touch-up or adjustment can unexpectedly stretch into the early hours of the morning. For us,
                painting offers a thrill unlike any other form of entertainment, as it empowers us to be the architects of our own worlds.
                With each brushstroke, we imbue the canvas with our emotions and imagination, creating a tapestry of mood and desire that
                resonates with anyone who gazes upon it.
            </h3>
            <br />

            <div className="commitmentTwosections">
                <div className="highestProffesional">
                    <div className={highlited === 1 ? "higlitedHeader" : "greyHeader"} onClick={() => handleCick(1)}>
                        {highlited === 1 ? <img src={arrow} className="arrowRightCommitmentSection" alt="arrow-right"></img> : null}
                        {blogs[7].title}
                    </div>
                    <div className={highlited === 2 ? "higlitedHeader" : "greyHeader"} onClick={() => handleCick(2)}>
                        {highlited === 2 ? <img src={arrow} className="arrowRightCommitmentSection" alt="arrow-right"></img> : null}
                        {blogs[8].title}
                    </div>
                    {/* <div className={highlited === 3 ? "higlitedHeader" : "greyHeader"} onClick={() => handleCick(3)}>
                        {highlited === 3 ? <img src={arrow} className="arrowRightCommitmentSection" alt="arrow-right"></img> : null}
                        {blogs[9].title}
                    </div>
                    <div className={highlited === 4 ? "higlitedHeader" : "greyHeader"} onClick={() => handleCick(4)}>
                        {highlited === 4 ? <img src={arrow} className="arrowRightCommitmentSection" alt="arrow-right"></img> : null}
                        {blogs[10].title}
                    </div>
                    <div className={highlited === 5 ? "higlitedHeader" : "greyHeader"} onClick={() => handleCick(5)}>
                        {highlited === 5 ? <img src={arrow} className="arrowRightCommitmentSection" alt="arrow-right"></img> : null}
                        {blogs[11].title}
                    </div>
                    <div className={highlited === 6 ? "higlitedHeader" : "greyHeader"} onClick={() => handleCick(6)}>
                        {highlited === 6 ? <img src={arrow} className="arrowRightCommitmentSection" alt="arrow-right"></img> : null}
                        {blogs[12].title}
                    </div>
                    <div className={highlited === 7 ? "higlitedHeader" : "greyHeader"} onClick={() => handleCick(7)}>
                        {highlited === 7 ? <img src={arrow} className="arrowRightCommitmentSection" alt="arrow-right"></img> : null}
                        {blogs[13].title}
                    </div> */}
                </div>

                {(() => {
                    switch (highlited) {
                        case 1:
                            return (
                                <div className="imageCommitmentSection">
                                    <img className="borderR20" src="./images/5In dreams.jpeg" alt="imageText1" />
                                    <div className="textImageCommitment">
                                        {blogs[7].body}
                                    </div>
                                    {
                                        loggedIn === true ?

                                            <Link className="adminEditLink" to={`/blogss/${7}`}>
                                                Edit
                                            </Link>
                                            : null
                                    }
                                </div>
                            );

                        case 2:
                            return (
                                <div className="imageCommitmentSection">
                                    <img className="borderR20" src="./images/1TobiDeidara.jpeg" alt="imageText2" />
                                    <div className="textImageCommitment">
                                        {blogs[8].body}
                                    </div>
                                    {
                                        loggedIn === true ?

                                            <Link className="adminEditLink" to={`/blogss/${8}`}>
                                                Edit
                                            </Link>
                                            : null
                                    }
                                </div>
                            );

                        default:
                            return (
                                <div className="imageCommitmentSection">
                                    <img className="borderR20" src="./images/5In dreams.jpeg" alt="imageText1" />
                                    <div className="textImageCommitment">
                                        We aim to mantain the highest level of professionalism and
                                        ethical standards in all our interactions and services.
                                    </div>
                                    {
                                        loggedIn === true ?

                                            <Link className="adminEditLink" to={`/blogss/${7}`}>
                                                Edit
                                            </Link>
                                            : null
                                    }
                                </div>
                            );

                    }
                })()}




            </div>
            <br />
        </div >
    );
}

export default Artists;