import { useState } from "react";
import law from './assets/Law.svg';
import feather from './assets/Feather.svg';
import feather2 from './assets/Feather2.svg';
import emailjs from 'emailjs-com';

const QuickOrder = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [custom, setCustom] = useState('Albania');
    const [phoneNumber, setPhoneNUmber] = useState('');
    const [serviceTypes, setServiceTypes] = useState(['Birth Certificates']);
    const [clickedSubmit, setClickedSubmit] = useState(0);


    // const handleFileChange = (e) => {
    //     const selectedFile = e.target.files[0];
    //     setFile(selectedFile);
    // };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name.trim() || !email.trim() || !phoneNumber.trim()) {
            // If any of the fields are blank, display an error message or perform other actions
            console.error('Please fill out all required fields.');
            return; // Stop further execution
        }
        // Your EmailJS service ID, template ID, and user ID
        const serviceId = 'service_k6zr2bw';
        const templateId = 'template_jq8leb8';
        const userId = 'vQ7oFMBSzswdACJxX';

        emailjs.send(serviceId, templateId, {
            name: name,
            email: email,
            painting_name: phoneNumber
            // other variables...
        }, userId)
            .then((response) => {
                console.log('Email sent successfully:', response);
                // Optionally, you can show a success message to the user
                setClickedSubmit(1);
            }, (error) => {
                console.error('Email sending failed:', error);
                setClickedSubmit(2);
                // Optionally, you can show an error message to the user
            });

        // Reset form fields after submission
        setName('');
        setEmail('');
        setPhoneNUmber('');
        // Reset other state variables...
    };

    const addServiceType = () => {
        setServiceTypes(prevTypes => [...prevTypes, 'Birth Certificates']);
        console.log(serviceTypes);
    }
    const handleServiceTypeChange = (e, index) => {
        const updatedServiceTypes = [...serviceTypes];
        updatedServiceTypes[index] = e.target.value;
        setServiceTypes(updatedServiceTypes);
    }

    return (
        <div id="quickOrderSection" className="quickOrderSection">
            <div className="leftOrder">
                <img className="goRight" src={feather} alt="feather" />
                <img src={feather2} alt="feather2" />
            </div>
            <div className="centerOrder">
                <div >
                    <div className="quickOrderHeader">
                        Quick Order:
                    </div>

                    <div className="subHeaderFillOut">
                        Fill Out to Get Started
                    </div>
                </div>
                <div className="formOrder">
                    <form
                        onSubmit={handleSubmit}
                        className="formQuickOrder"
                    >
                        <div className="column inputLine">
                            <label htmlFor="name">Your Name</label>
                            <input
                                className="orderInputField"
                                type="text"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div className="column inputLine">
                            <label htmlFor="">Your Email</label>
                            <input
                                className="orderInputField"
                                type="text"
                                required
                                value={email}
                                name="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="row flex gap10betweenOrder">
                            <div className="flex column width50percent">
                                <label htmlFor=""> I want custom Art</label>
                                <select className="orderSelectField" value={custom} onChange={(e) => setCustom(e.target.value)} name="CustomArt">
                                    <option value="Albania">Yes</option>
                                    <option value="Andorra">No</option>

                                </select>
                            </div>
                            <div className="flex column width50percent">
                                <label htmlFor=""> Name of Painting</label>
                                <input
                                    className="orderSelectField"
                                    type="text"
                                    required
                                    value={phoneNumber}
                                    name="phone Number"
                                    onChange={(e) => setPhoneNUmber(e.target.value)}
                                />
                            </div>
                        </div>


                        <button className="submit width100" type="submit">
                            {clickedSubmit == 1 ? "Done" :
                                clickedSubmit == 0 ?
                                    "Submit" : "Service Unavailable"}
                        </button>
                        <div className="byClickingAgree">
                            By clicking “Submit” button you agree to the
                        </div>

                    </form>
                </div>
            </div>
            <div className="rightOrder">
                <img className="width100" src={feather2} alt="feather2" />
                <img className="goRight width100" src={feather} alt="feather" />

            </div>
        </div>
    );
}

export default QuickOrder;