const data = {
    "blogs": [
        {
            "title": "My First Blog",
            "body": "just a sample not used ...",
            "author": "mario & luigi",
            "id": "1gas"
        },
        {
            "id": "1",
            "title": "Exploring Artistry Where Styles Converge",
            "body": "Step into the world of artistic excellence at E&M Gallery, your premier destination for exquisite and diverse paintings crafted with unparalleled skill and passion. "
        },
        {
            "id": "2",
            "title": "Nostalgia - Milena Fileva",
            "body": "Nostalgia - finalized in November 2023, captures the essence of a fleeting moment. Inspired by an photograph captured by Ventsi Filev during a solitary autumn windsurfing retreat, the artwork encapsulates the poignant transition from summer's embrace to the arrival of autumn. According to him carrying exactly the mood of 'summer is gone, autumn is coming...' (part of an old Bulgarian song). With an innate understanding of the sea's allure during quieter seasons, Ventsi's portfolio boasts a collection of captivating imagery, revealing the hidden beauty of seaside solitude typically reserved for the adventurous few."
        },
        {
            "id": "3",
            "title": "In dreams - Milena Fileva ",
            "body": "Completed on September 9, 2023, this painting was born from the profound inspiration drawn from Ekaterina Koseva's captivating photograph. Infused with her essence of femininity, tenderness, tranquility, elegance, beauty, harmony, and serenity, I endeavored to echo these qualities within the artwork. It embodies a fusion with both the inception and conclusion, embracing the infinite. This painting represents the beginning of my professional career, as I received a comment about it from Silvia Gancheva, whom I met by chance at an exhibition and subsequently became my mentor for a while."
        },
        {
            "id": "4",
            "title": "She - Milena Fileva",
            "body": `This project marks a significant milestone for me, originating in Silvia Gancheva's studio upon her suggestion of a topic that deeply resonated with me. With earnest enthusiasm, I embarked on this endeavor, eager to bring it to life on canvas. The completion of this painting instilled in me a newfound sense of confidence, emboldening me to pursue my artistic journey with conviction. "She" embodies the essence of urban modernity intertwined with the soulful yearnings of a gentle, ordinary girl amidst the bustling calls and quests of city life.`
        },
        {
            "id": "5",
            "title": "ABOUT US",
            "body": " E&M Gallery , nested in the outskirts of Sofia, celebrates the vobrant world of contemporary art. Our art studio showcases a diverse collection of artworks. With a team of passionate curators and art enthusiasts, we curate exibitions that ignite creativity and inspire imagination. Famous for our commitment to quality and cusomer satisfaction, we invite you to explore our work and find the perfect piece to adorn your space.Whether you're seeking a stunning centerpiece for your home or a thoughtful gift for a loved one, our collection offers a diverse array of artworks to suit every taste and style. Additionally, we are pleased to take custom orders, allowing you to commission personalized artworks tailored to your unique vision and preferences."
        },
        {
            "id": "6",
            "title": "Uniquely Crafted for You",
            "body": "Looking to commission a bespoke painting for a special occasion like a wedding gift? Let us bring your vision to life with a personalized masterpiece tailored to your exact specifications."
        },
        {
            "id": "7",
            "title": "Milena Fileva",
            "body": "Favourite quote: “Art washes away from the soul the dust of everyday life.”-Pablo Picasso"
        },
        {
            "id": "8",
            "title": "Emilia Vasileva",
            "body": "Favourite quote: Fantazy is my muse."
        },
        {
            "id": "9",
            "title": "Some Stories",
            "body": ""
        },
        {
            "id": "10",
            "title": "",
            "body": ""
        },
        {
            "id": "11",
            "title": "",
            "body": ""
        },
        {
            "id": "12",
            "title": "",
            "body": ""
        },
        {
            "id": "13",
            "title": "",
            "body": ""
        },
        {
            "id": "14",
            "title": "",
            "body": ""
        },
        {
            "id": "15",
            "title": "",
            "body": ""
        },
        {
            "id": "16",
            "title": "",
            "body": ""
        },
        {
            "id": "17",
            "title": "",
            "body": ""
        },
        {
            "id": "18",
            "title": "",
            "body": ""
        },
        {
            "id": "19",
            "title": "Oil Painting",
            "body": "Rich Texture: Oil paints boast a luxurious and versatile texture, allowing customers to create captivating artworks with depth and dimension. With a wide spectrum of colors and blending capabilities, oil painting offers endless possibilities for expressing creativity and capturing intricate details."
        },
        {
            "id": "20",
            "title": "Acrylic Painting",
            "body": "Vibrant Colors: Acrylic paints come in a wide range of colors, from bold primaries to subtle neutrals. These paints have a high pigment concentration, resulting in vibrant and intense hues that retain their brightness even after drying."
        },
        {
            "id": "21",
            "title": "Watercoloring Painting",
            "body": "Uses water-based paints that are transparent and diluted with water. Typically painted on paper, creating delicate and translucent effects."
        },
        {
            "id": "22",
            "title": "Pastel Painting",
            "body": "Involves using dry pastel sticks or pencils to create colorful and expressive artwork. Can be applied in various ways, including blending, layering, and hatching."
        },
        {
            "id": "23",
            "title": "Landscape",
            "body": "Focuses on depicting natural scenery such as mountains, forests, rivers, and skies. Emphasizes the beauty and majesty of the natural world."
        },
        {
            "id": "24",
            "title": "Genre Painting",
            "body": "Portrays everyday life scenes, activities, and settings, often depicting ordinary people engaged in common activities. Offers insights into social customs, cultural practices, and human interactions."
        },
        {
            "id": "25",
            "title": "Fantazy",
            "body": "Explores imaginative and fantastical themes, creatures, and settings inspired by mythology, folklore, or the artist's imagination. Allows for creative expression and escapism from reality."
        },
        {
            "id": "26",
            "title": "Still Life",
            "body": "Consists of inanimate objects arranged in a composition, such as flowers, fruit, household items, or food. Allows artists to explore light, shadow, texture, and color in a controlled setting."
        },
        {
            "id": "27",
            "title": "In my opinion, creativity through paintings aims to give positive moods, namely inner peace, timelessness, worship of nature, etc. I wish everyone could be a doer and contribute more to this world. I enjoy drawing female images and figures for a few main reasons. If it is believed that a person is the highest creature of nature, then undoubtedly the woman is the more beautiful version. It also symbolizes the incarnation of nature in the image of the mother, associated with love, wisdom, happiness, luck, beauty, prosperity, etc.",
            "body": "The main subjects of my paintings are female images/figures and landscape compositions/nature."
        },
        {
            "id": "28",
            "title": "",
            "body": ""
        },
        {
            "id": "29",
            "title": "",
            "body": ""
        },
        {
            "id": "30",
            "title": "",
            "body": ""
        },
        {
            "id": "31",
            "title": "",
            "body": ""
        },
        {
            "id": "32",
            "title": "Marine Painting",
            "body": "Captures scenes and subjects related to the sea, including ships, seascapes, marine life, and coastal landscapes. Explores themes of exploration, adventure, and the sublime beauty of the ocean."
        },
        {
            "id": "",
            "title": "",
            "body": ""
        }
    ],
    "authentication": [
        {
            "username": "565269115",
            "password": "NotMyDaddy@2024",
            "id": "6939"
        }
    ],
    "paintings": [
        {
            "id": 1,
            "name": "Sea coast",
            "size": "30 x 30cm",
            "price": "200 Euro",
            "stars": 5,
            "url": "./images/8Sea coast.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 2,
            "name": "Blissful day",
            "size": "35 x 27cm",
            "price": "200 Euro",
            "stars": 5,
            "url": "./images/2Blissful day.jpg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 3,
            "name": "Coffee time",
            "size": "40 x 20cm",
            "price": "150 Euro",
            "stars": 5,
            "url": "./images/3Coffee time.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 4,
            "name": "Girlfriends",
            "size": "40 x 20cm",
            "price": "200 Euro",
            "stars": 5,
            "url": "./images/4Girlfriends.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 5,
            "name": "In dreams",
            "size": "25 x 30cm",
            "price": "230 Euro",
            "stars": 5,
            "url": "./images/5In dreams.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 6,
            "name": "Love moon",
            "size": "40 x 30cm",
            "price": "180 Euro",
            "stars": 5,
            "url": "./images/6Love moon.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 7,
            "name": "Orange marmalade",
            "size": "40 x 30cm",
            "price": "200 Euro",
            "stars": 5,
            "url": "./images/7Orange marmalade.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 8,
            "name": "Ballet",
            "size": "30 x 25cm",
            "price": "180 Euro",
            "stars": 5,
            "url": "./images/1Ballet.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 9,
            "name": "She",
            "size": "40 x 30cm",
            "price": "230 Euro",
            "stars": 5,
            "url": "./images/9She.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 10,
            "name": "The bench",
            "size": "40 x 30cm",
            "price": "150 Euro",
            "stars": 5,
            "url": "./images/10The bench.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 11,
            "name": "Nostalgic",
            "size": "40 x 30cm",
            "price": "160 Euro",
            "stars": 5,
            "url": "./images/11Nostalgic.jpeg",
            "artist": "Milena Fileva",
            "paint": "acrylic"
        },
        {
            "id": 12,
            "name": "Destiny",
            "size": "45 x 35cm",
            "price": "180 Euro",
            "stars": 5,
            "url": "./images/12Destiny.jpeg",
            "artist": "Emilia Vasileva",
            "paint": "oil paint"
        },
        {
            "id": 13,
            "name": "TobiDeidara",
            "size": "30 x 25cm",
            "price": "150 Euro",
            "stars": 5,
            "url": "./images/1TobiDeidara.jpeg",
            "artist": "Emilia Vasileva",
            "paint": "oil paint"
        },
        {
            "id": 14,
            "name": "Storm Mith",
            "size": "30 x 30cm",
            "price": "100 Euro",
            "stars": 5,
            "url": "./images/14StormMith.jpeg",
            "artist": "Emilia Vasileva",
            "paint": "oil paint"
        },

    ]
}

export default data;