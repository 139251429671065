import { useEffect, useState } from "react";
import useFetch from "./useFetch";
import { useHistory } from "react-router-dom";
import SecondPart from "./secondPart";
import AboutUsSection from "./AboutUs";
import UniquelyCrafted from "./UniquelyCrafted";
import Artists from "./Artists";
import ArtworkShowcase from "./ArtworkShowcase";
import Questions from "./Questions";
import QuickOrder from "./QuickOrder";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Popup from "./Popup";
import data from './data';

const Home = () => {

    const loggedIn = false;
    const history = useHistory();
    const goToLogin = () => {
        history.push("/login");
    }
    const paintings = data.paintings;
    const blogs = data.blogs;

    const [isPopupVisible, setPopupVisible] = useState(false);
    const [selectedBubbleData, setSelectedBubbleData] = useState(null);

    const handleBubbleClick = (number, blogs) => {
        console.log(blogs);
        if (blogs) {
            switch (number) {
                case 1:
                    setSelectedBubbleData({
                        title: blogs[19].title,
                        content: blogs[19].body,
                        buttonText: 'Click to Order',
                    });
                    setPopupVisible(true);
                    break;
                case 2:
                    setSelectedBubbleData({
                        title: blogs[20].title,
                        content: blogs[20].body,
                        buttonText: 'Make an appointment',
                    });
                    setPopupVisible(true);
                    break;
                case 3:
                    setSelectedBubbleData({
                        title: blogs[21].title,
                        content: blogs[21].body,
                        buttonText: 'Make an appointment',
                    });
                    setPopupVisible(true);
                    break;
                case 4:
                    setSelectedBubbleData({
                        title: blogs[22].title,
                        content: blogs[22].body,
                        buttonText: 'Make an appointment',
                    });
                    setPopupVisible(true);
                    break;
                case 5:
                    setSelectedBubbleData({
                        title: blogs[23].title,
                        content: blogs[23].body,
                        buttonText: 'Make an appointment',
                    });
                    setPopupVisible(true);
                    break;
                case 6:
                    setSelectedBubbleData({
                        title: blogs[24].title,
                        content: blogs[24].body,
                        buttonText: 'Make an appointment',
                    });
                    setPopupVisible(true);
                    break;
                case 7:
                    setSelectedBubbleData({
                        title: blogs[25].title,
                        content: blogs[25].body,
                        buttonText: 'Make an appointment',
                    });
                    setPopupVisible(true);
                    break;
                case 8:
                    setSelectedBubbleData({
                        title: blogs[32].title,
                        content: blogs[32].body,
                        buttonText: 'Make an appointment',
                    });
                    setPopupVisible(true);
                    break;
                case 9:
                    setSelectedBubbleData({
                        title: blogs[26].title,
                        content: blogs[26].body,
                        buttonText: 'Learn more',
                    });
                    setPopupVisible(true);
                    break;
            }
        }


    };

    return (
        <div className={"home"}>


            <div>
                <div id="firstSection" className="firstSection">
                    <span className="whitishBack"></span>
                    <div className="firstSectionTextBuble">
                        <h4> Art gallery located in Sofia |  Opening at 10:00 AM</h4>
                        <h1 className="bigtitle"> {blogs[1].title}</h1>

                        <h3 className="welcome">
                            {blogs[1].body}
                        </h3>
                        {
                            loggedIn === true ?

                                <Link className="adminEditLink" to={`/blogss/${1}`}>
                                    Edit
                                </Link>
                                : null
                        }
                        <div className="whiteButton">
                            <a className="phoneRedirect" href="#paintings">View Gallery</a>
                        </div>
                        <Link to='/Profile#Artists' className="twoButtns viewArtists text-decNone">
                            View Artists
                        </Link>
                        <div className="rightBot">
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(1, blogs)}> Oil Painting</span>
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(2, blogs)}> Acrylic Painting</span>
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(3, blogs)}> Watercolor Painting</span>
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(4, blogs)}> Pastel Painting</span>
                        </div>
                        <div className="rightBot">
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(5, blogs)}> Landscape</span>
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(6, blogs)}> Genre Painting</span>
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(7, blogs)}> Fantazy</span>
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(8, blogs)}> Marine Painting</span>
                            <span className="bubbleTransparent" onClick={() => handleBubbleClick(9, blogs)}> Still Life</span>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>

                {isPopupVisible && selectedBubbleData && (
                    <Popup onClose={() => setPopupVisible(false)} dynamicData={selectedBubbleData} />
                )}
                <br />
                <br />
                <ArtworkShowcase clientVoices={paintings}></ArtworkShowcase>
                <br />
                <br />

                <div className="someStoriesTitle">
                    {blogs[9].title}
                </div>
                <SecondPart loggedIn={loggedIn} blogs={blogs}></SecondPart>
                <br />
                <br />

                <AboutUsSection loggedIn={loggedIn} blogs={blogs}></AboutUsSection>
                <br />
                <br />
                <UniquelyCrafted loggedIn={loggedIn} blogs={blogs}></UniquelyCrafted>

                {/* <CommitmentSection loggedIn={loggedIn} blogs={blogs}></CommitmentSection> */}


                {/* <Questions loggedIn={loggedIn} blogs={blogs}></Questions> */}

                <br />
                <br />
                <QuickOrder></QuickOrder>
                <br />
                <br />
                <br />

                <div className="elfsight-app-2ee96b75-be73-405d-b062-6f4620a93b19" data-elfsight-app-lazy></div>
            </div>


        </div>
    );
}

export default Home;