import { useEffect, useState, useRef } from "react";
import Artists from "./Artists";
import data from './data';
import LayoutPaintings from "./LayoutPaintings";

const Profile = () => {

    const loggedIn = false;
    const blogs = data.blogs;
    const paintings = data.paintings;
    const [artist, setArtist] = useState('Milena Fileva');
    const artistsRef = useRef(null);

    const handleArtistChange = (newArtist) => {
        setArtist(newArtist);
    }
    useEffect(() => {
        // Scroll to the Artists section after rendering
        if (artistsRef.current) {
            artistsRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    return (
        <div ref={artistsRef}>
            <Artists loggedIn={loggedIn} blogs={blogs} onArtistChange={handleArtistChange}></Artists>
            <p className="commitmentTitle">{artist}'s Collection</p>
            <h3 className="milenaIntro">{artist == 'Milena Fileva' ? blogs[27].title : blogs[28].title}</h3>
            <LayoutPaintings paintings={paintings} artist={artist}></LayoutPaintings>
            <h4 className="milenaRetro">{artist == 'Milena Fileva' ? blogs[27].body : blogs[28].body}</h4>
        </div>
    );
}

export default Profile;