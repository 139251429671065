import phone from './assets/Phone.svg';
import { Link } from 'react-router-dom';
import logo from './assets/nav-logo.png';
const Navbar = () => {
    const phoneNumber = '+359893350100';

    return (
        <nav className="navbar">
            <div className='flexNavbar'>
                <img src={phone} alt="phone-icon" />
                <div className='callNowHeader'>
                    <a href={`tel:${phoneNumber}`} className='callNowHeader'>
                        Call now
                    </a>
                </div>
                <h1 id="header" className='redColor'>Call now: +359 89 3350100</h1>
            </div>
            <div className='logo'>
                <Link to="/">
                    <img className='logoLink' src={logo} alt="logo-name" />
                </Link>
            </div>

            <div className="links">
                <a className="borderR32" href="/#quickOrderSection" >
                    Quick Order
                </a>
            </div>
            <div className="orderNavbar">
                <a className="borderR32" href="/#quickOrderSection" >
                    Order
                </a>
            </div>
        </nav>
    );
}

export default Navbar;