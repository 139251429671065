import svg from './assets/fi_5679332.svg'
import svg2 from './assets/fi_2.svg';
import svg3 from './assets/fi_3.svg';
import arrow from './assets/right-arrow.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const SecondPart = ({ loggedIn, blogs }) => {
    const handleRequestQuote = (e) => {
        e.preventDefault(); // Prevent the default behavior of the anchor tag
        window.location.href = "#paintings";
    }
    return (
        <div className="secondSection">
            <div className="threeCards">
                <div className='row spaceBetween'>
                    <img src={svg2} alt="pencil" />
                    <img className='smallSection2Pictures' src="./images/11Nostalgic.jpeg" alt="nostalgic drawing" />
                </div>
                <div className='secondSectionHeader'>
                    {blogs[2].title}
                </div>
                <div>
                    {blogs[2].body}
                </div>
                {
                    loggedIn === true ?

                        <Link className="adminEditLink" to={`/blogss/${2}`}>
                            Edit
                        </Link>
                        : null
                }
                <div className='rowOnCard'>

                    <span className='requestQuote'>
                        <a onClick={(e) => handleRequestQuote(e)} className='text-decNone black'>
                            Discover more
                        </a>
                    </span>
                    <a href="#quickOrderSection" className='text-decNone'>
                        <span className='getConsultation'>
                            Quick order
                            <img src={arrow} alt="arrow-right" />
                        </span>
                    </a>

                </div>
            </div>
            <div className="threeCards">
                <div className='row spaceBetween'>
                    <img src={svg2} alt='pencil' />
                    <img className='smallSection2Pictures' src="./images/5In dreams.jpeg" alt="nostalgic drawing" />
                </div>
                <div className='secondSectionHeader'>
                    {blogs[3].title}
                </div>
                <div>
                    {blogs[3].body}
                </div>
                {
                    loggedIn === true ?

                        <Link className="adminEditLink" to={`/blogss/${3}`}>
                            Edit
                        </Link>
                        : null
                }
                <div className='rowOnCard'>
                    <span className='requestQuote'>
                        <a onClick={handleRequestQuote} className='text-decNone black'>
                            Discover more
                        </a>
                    </span>
                    <a href="#quickOrderSection" className='text-decNone'>
                        <span className='getConsultation'>
                            Quick order
                            <img src={arrow} alt="arrow-right" />
                        </span>
                    </a>
                </div>
            </div>
            <div className="threeCards">
                <div className='row spaceBetween'>
                    <img src={svg2} alt="thumbnail" />
                    <img className='smallSection2Pictures' src="./images/9She.jpeg" alt="nostalgic drawing" />
                </div>
                <div className='secondSectionHeader'>
                    {blogs[4].title}
                </div>
                <div>
                    {blogs[4].body}
                </div>
                {
                    loggedIn === true ?

                        <Link className="adminEditLink" to={`/blogss/${4}`}>
                            Edit
                        </Link>
                        : null
                }
                <div className='rowOnCard'>
                    <span className='requestQuote'>
                        <a onClick={handleRequestQuote} className='text-decNone black'>
                            Discover more
                        </a>
                    </span>

                    <a href="#quickOrderSection" className='text-decNone'>
                        <span className='getConsultation'>
                            Quick order
                            <img src={arrow} alt="arrow-right" />
                        </span>
                    </a>
                </div>
            </div>

        </div>
    );
}

export default SecondPart;